import React from 'react'
import styled from 'styled-components'

import { Container } from 'components/blocks/sections/directories/AllEntries/ServiceEntries'
import { MediaStoryblok } from 'lib/storyblok/types'
import { cn } from 'lib/utils/tailwind'

import { Media } from './Media'

type Props = {
  block: MediaStoryblok
}

const MediaBlock = ({ block, ...props }: Props): JSX.Element => {
  const { source, width } = block

  return (
    <>
      {source?.filename && (
        <MediaContainer {...props}>
          <Wrapper width={width}>
            <Media
              source={source}
              className={cn(width === 'small' ? 'w-[420px]' : 'w-full')}
            />
          </Wrapper>
        </MediaContainer>
      )}
    </>
  )
}

export { MediaBlock }

const MediaContainer = styled(Container)`
  padding-top: 1rem;
  padding-bottom: 1rem;

  max-width: none;
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.media.md} {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`

const Wrapper = styled.div<{ width?: MediaStoryblok['width'] }>`
  width: 100%;
  width: ${({ width }) => {
    const variants: Record<string, string> = {
      large: '82rem',
      medium: '60rem',
      small: '37rem',
    }
    return variants[width || 'large']
  }};
`
